// import Vue from "vue"
import Vuex from "vuex"
// Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // openTab: [],
    activeProductIndex: '', //睡眠产品tab
    activeMenuIndex: '',    //菜单menu
    navMenuData:[
        { index: "/ourSleep",
            name: "睡眠健康" ,
            child: [
                { index: "1-1", name: "我们的睡眠" },
                { index: "1-2", name: "睡眠不好的症状" },
                { index: "1-3", name: "睡眠健康小百科" },
                { index: "1-4", name: "睡眠测试", 
                child: [
                    { index: "1-4-1", name: "https://oss.bioyishi.com/app/wx-prod.jpg",showImg:true }
                    ]  
                }]
        },
        { index: "/serviceCenter",
            name: "服务中心",
            child: [
                { index: "2-1", name: "乙十睡眠充电站" },
                { index: "2-2", name: "合作销售网络" }
            ]
        },
        { index: "/professionals",
            name: "专业人士",
            child: [
                { index: "3-1", name: "乙十睡眠业务经理" },
                { index: "3-2", name: "睡眠呼吸暂停" },
                { index: "3-3", name: "医疗产品" }
            ]
        },
        {
            index: "/sleepProduct",
            name: "睡眠产品",
            child: [
                { index: "medical", name: "睡眠呼吸机" },
                { index: "ruxiang", name: "乳香精油系列" },
                { index: "alasiguo", name: "阿拉斯果系列"},
                { index: "sleepProject", name: "睡眠调理项目"}
            ]
        },
        {
            index: "/aboutUs",
            name: "关于我们",
            child: [
                { index: "5-1", name: "企业简介" },
                { index: "5-2", name: "企业文化" },
                { index: "5-3", name: "企业动态"},
                { index: "5-4", name: "加入我们"}
            ]
        }
    ]
  },
  mutations: {
    // 设置高亮tab
    set_active_product_index (state, index) {
      state.activeProductIndex = index
    },

    set_active_menu_index (state, index) {
        state.activeMenuIndex = index
    }
  }
})
