import { defineComponent } from 'vue';
// import DialogView from './components/DialogView.vue'
import NavMenu from "./components/NavMenu.vue";
export default defineComponent({
  name: 'App',
  components: {
    NavMenu
    // 'dialog-bar': DialogView,
  },
  data() {
    return {
      sendVal: false,
      imageUrl: '',
      navMenuData: this.$store.state.navMenuData,
      showDownLoad: false,
      downloadContext: "<div style='width: 600px;'>" +
      "<div style=\"position: absolute;top:0;left:0;background: #ECECEC; width: 100%; height: 30px;text-align: left;font-size: 18px;font-weight: 500;color: #333333;line-height:30px;\"><span style=\"margin-left:20px;\">扫一扫</span></div>" +
      "<div style=\"width: 100%;height:230px;text-align:center; \" >\n" +
      "                  <div style=\"width:200px;height:30px;float:left;font-size: 18px;font-family: PingFang-SC-Medium, PingFang-SC;font-weight: 500;color: #333333;letter-spacing: 1px;\">android手机</div>\n" +
      "                  <div style=\"width:200px;height:30px;float:left;font-size: 18px;font-family: PingFang-SC-Medium, PingFang-SC;font-weight: 500;color: #333333;letter-spacing: 1px;\">苹果手机</div>\n" +
      "                  <div style=\"width:200px;height:30px;float:left;font-size: 18px;font-family: PingFang-SC-Medium, PingFang-SC;font-weight: 500;color: #333333;letter-spacing: 1px;\">微信小程序</div>\n" +
      "                  <img style=\"width:200px;height:200px;float:left;\" src=\"https://oss.bioyishi.com/app/android-prod.png\"/>\n" +
      "                  <img style=\"width:200px;height:200px;float:left;\" src=\"https://oss.bioyishi.com/app/ios-prod.png\"/>\n" +
      "                  <img style=\"width:200px;height:200px;float:left;\" src=\"https://oss.bioyishi.com/app/wx-prod.jpg\"/>\n" +
      "              </div>" +
      "</div>" };

  },
  methods: {
    // 回到主页
    backToHome() {
      this.$store.commit('set_active_menu_index', ''); //设置菜单高亮
      this.$router.push({ path: '/home' });
    },
    jumpToPage(key, toId, isJumpPosition) {
      this.$store.commit('set_active_menu_index', toId); //设置菜单高亮
      localStorage.removeItem("toId");
      if (key === this.$route.path) {//如果当前已经在这个页面不跳转 直接去
        if (key === '/sleepProduct') {//如果是睡眠产品
          this.$store.commit('set_active_product_index', toId);
          // 让页面回到顶部
          document.documentElement.scrollTop = 0;
        } else
        {
          let toElement = document.getElementById(toId);
          if (toElement)
          toElement.scrollIntoView(true);
        }
      } else
      {//否则跳转路由
        // 如果点击的是主页不需要描点
        if (isJumpPosition) {
          localStorage.setItem("toId", toId);
        }
        this.$router.push({ path: key });
      }
    },
    showDownload() {
      this.showDownLoad = !this.showDownLoad;
    },
    openMask(url) {
      this.imageUrl = url;
      this.sendVal = true;
    },
    clickCancel() {
      console.log('点击了取消');
    },
    clickDanger() {
      console.log('这里是danger回调');
    },
    clickConfirm() {
      console.log('点击了confirm');
    } } });