/**
 * createRouter 这个为创建路由的方法
 * createWebHashHistory 这个就是vue2中路由的模式，
 *                      这里的是hash模式，这个还可以是createWebHistory等
 * RouteRecordRaw 这个为要添加的路由记录，也可以说是routes的ts类型
 */
 import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router';
 // 路由记录
 const routes:Array<RouteRecordRaw> = [
  {
    path:'/',
    //redirect 是重新定向
    redirect:'/home',
    meta: {
      title: '上海乙十生物医疗科技有限公司'
    }
    },
   {
    //首页
     path: '/home',
     name: 'home',
     component: () => import("@/views/HomeView.vue"),
     alias: '/index',
     meta: {
       title: '上海乙十生物医疗科技有限公司'
     }
   },
   {
    // 我们的睡眠
    path: "/ourSleep",
    name: "ourSleep",
    component: () => import("@/views/sleepHealth/OurSleep.vue"),
    meta: {
      title: '我们的睡眠'
    }
  },
 {
    // 文章详情
    path: "/newsDetail/:id",
    name: "newsDetail",
    component: () => import("@/views/sleepHealth/NewsDetail.vue"),
    meta: {
      title: ''
    }
  },
  {
    //服务中心 
     path: '/serviceCenter',
     name: 'serviceCenter',
     component: () => import("@/views/serviceCenter/ServiceCenter.vue"),
     meta: {
       title: '服务中心'
     }
   },
   {
    //专业人士 
     path: '/professionals',
     name: 'professionals',
     component: () => import("@/views/professionals/ProfessionalTeam.vue"),
     meta: {
       title: '专业人士'
     }
   },
   {
    //睡眠产品
    path: '/sleepProduct',
    name: 'sleepProduct',
    component: () => import("@/views/sleepProduct/SleepProduct.vue"),
    meta: {
      title: '睡眠产品'
    }
   },
   {
    //关于我们
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import("@/views/aboutUs/AboutUs.vue"),
    meta: {
      title: '关于我们'
    }
   }
 ];
 
 const router = createRouter({
   history: createWebHashHistory(),
   routes,
   
 });


 export default router;
 