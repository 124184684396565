
// import { moduleExpression } from '@babel/types'

// import {ref} from 'vue'
export default {
  name: 'NavMenu',
  components: {},
  computed: {
    cuserList() {
      return this.$store.state.activeMenuIndex;
    } },

  data() {
    return {
      activeIndex: '',
      navMenuData: this.$store.state.navMenuData };

  },
  watch: {
    cuserList(newVal, oldVal) {
      if (newVal)
      this.activeIndex = newVal;
    } },

  methods: {
    // 回到主页
    backToHome() {
      this.$store.commit('set_active_menu_index', ''); //设置菜单高亮
      this.activeIndex = '';
      this.$router.push({ path: '/home' });
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath)
    },
    handClick(key, toId, isJumpPosition) {
      // console.log(key, toId)
      this.activeIndex = toId;
      localStorage.removeItem("toId");
      if (key === this.$route.path) {//如果当前已经在这个页面不跳转 直接去

        if (key === '/sleepProduct') {//如果是睡眠产品
          this.$store.commit('set_active_product_index', toId);
        } else if (isJumpPosition) {
          let toElement = document.getElementById(toId);
          toElement.scrollIntoView(true);
        }

      } else {//否则跳转路由
        if (isJumpPosition) {
          localStorage.setItem("toId", toId);
        }
        this.$router.push({ path: key });
      }

    } } };