import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from './store';
import "@/assets/css/global.scss";
// import 'element-plus/theme-chalk/el-message-box.css'
import 'element-plus/theme-chalk/el-message.css';
import 'element-plus/theme-chalk/el-loading.css'

router.beforeEach((to, from, next) => {
    const selectId = localStorage.getItem("toId");
    // console.log(selectId)
    if(to.name ==='sleepProduct' || !selectId){
        // 让页面回到顶部
        document.documentElement.scrollTop = 0
        // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
        
    }
    next()
    
})
 // 切换页面换title 全局的前置守卫
 router.afterEach((to, next) => {
    //获取 document.querySelector title = 直接改掉 这么简单
    const docum:any = document.querySelector('title')
    if(docum) {
        docum.innerText = to.meta.title
    }
  })

createApp(App)
    .use(router)
    .use(store)
    .mount('#app')
